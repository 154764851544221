import React, { Dispatch, SetStateAction } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import { LeafletMouseEvent } from "leaflet";
import "leaflet/dist/leaflet.css";
import "./styles.css";
import MarkersPopups from "./MarkersPopups";
import Polygons from "./Polygons";
import { styles } from "./styles";

interface IProps {
  setLatLng: Dispatch<SetStateAction<LeafletMouseEvent["latlng"] | undefined>>;
}

const Map = (props: IProps) => {
  const { setLatLng } = props;
  const MapEvents = () => {
    useMapEvents({
      mousemove(e: LeafletMouseEvent) {
        setLatLng(e.latlng);
      },
      mouseover(e: LeafletMouseEvent) {
        // map dragging false
      },
    });
    return null;
  };
  return (
    <MapContainer
      center={[40.378422, 47.842201]}
      zoom={8}
      scrollWheelZoom={true}
      style={styles.mapContainer}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkersPopups />
      <Polygons />
      <MapEvents />
    </MapContainer>
  );
};

export default Map;
