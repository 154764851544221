import React, { useRef, useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  Container,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Layout from "../../components/Layout";
// import './styles.css';
import ProfileTimeline from "./ProfileTimeline";
import Internships from "./Internships";
import { Fade } from "react-awesome-reveal";
// import useDetectScroll from '@smakss/react-scroll-direction';
// import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import SubSection from "./SubSection";
import ListSection from "./ListSection";
import { toolsLogos, languageLogos, softwareLogos } from "./data";
import { workTimeLine } from "../../data/timelines";

import SocialMedia from "./SocialMedia";
import VerticalTimeline, { TimelineData } from "./WorkExperience";

const About: React.FC = () => {
  const parentRef = useRef<any>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const colors = {
    primary: "#000",
    secondary: "#78AEFF",
  };
  const theme = useTheme();
  const isAboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileUp = useMediaQuery("(max-width:400px)");

  useEffect(() => {
    setInitialLoad(true);
  }, []);

  const padding10 = {
    padding: `0 2rem`,
    [theme.breakpoints.up("sm")]: {
      padding: `0 2rem`,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: `center`,
    },
    [theme.breakpoints.up("md")]: {
      padding: `0 5rem`,
    },
    [theme.breakpoints.up("lg")]: {
      padding: `0 10rem`,
    },
  };
  return (
    <Layout parentRef={parentRef}>
      <Box
        pt={10}
        sx={{
          background: `linear-gradient(${isAboveMd ? 90 : 180}deg, ${
            colors.primary
          } 67%, ${colors.secondary} 33%)`,
          paddingBottom: 5,
        }}
      >
        <Grid
          container
          maxWidth={1488}
          mr="auto"
          ml="auto"
          sx={padding10}
          pl={0}
        >
          <Grid item xs={12} sm={12} md={7} lg={7}>
            {initialLoad && (
              <Fade cascade={false} duration={500}>
                <Typography
                  variant="h1"
                  sx={{
                    color: colors.secondary,
                    fontSize: "3.4em",
                    textAlign: "center",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "4.4em",
                      textAlign: "inherit",
                    },
                    fontWeight: 900,
                    lineHeight: 1.1,
                    letterSpacing: "-0.03em",
                  }}
                >
                  {isDownMd
                    ? `Aspiring Product Manager & Full Stack Developer`
                    : `Aspiring Product Manager & Full Stack Developer`}
                </Typography>
              </Fade>
            )}
            <Fade
              cascade={false}
              duration={500}
              style={{
                fontSize: 28,
                [theme.breakpoints.up("md")]: {
                  fontSize: 32,
                },
                paddingTop: 24,
                color: "#ccf381",
              }}
            >
              Shant Serop Arslanian
            </Fade>
            <Stack
              spacing={isDownMd ? 2 : 6}
              mt={isDownMd ? 2 : 6}
              sx={{
                alignItems: isDownMd ? "center" : "inherit",
              }}
            >
              <Fade
                cascade={false}
                duration={500}
                style={{
                  fontSize: isMobileUp ? 18 : 22,
                  color: "white",
                  whiteSpace: "pre-wrap", // Allow wrapping
                  wordBreak: "break-word", // Break words when needed
                }}
              >
                <div>
                  - M.S. in Software Engineering from CSU, Northridge 2024
                </div>
              </Fade>
              <Fade
                cascade={false}
                duration={500}
                style={{
                  fontSize: isMobileUp ? 18 : 22,
                  color: "white",
                  whiteSpace: "pre-wrap", // Allow wrapping
                  wordBreak: "break-word", // Break words when needed
                }}
              >
                <div>
                  - B.S. in Computer Science from University of California,
                  Irvine 2021
                </div>
              </Fade>
              <Fade
                cascade={false}
                duration={500}
                style={{
                  fontSize: isMobileUp ? 18 : 22,
                  color: "white",
                  whiteSpace: "pre-wrap", // Allow wrapping
                  wordBreak: "break-word", // Break words when needed
                }}
              >
                <div>
                  - Full Stack Developer, crafting dynamic and responsive
                  websites
                </div>
              </Fade>
              <Fade
                cascade={false}
                duration={500}
                style={{
                  fontSize: isMobileUp ? 18 : 22,
                  color: "white",
                }}
              >
                - Future Product Manager, open to all opportunities
              </Fade>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} mt={isDownMd ? 3 : 0}>
            <Box sx={styles.profileImgBox}>
              <img
                src="https://res.cloudinary.com/dnu8ak7ku/image/upload/v1715586796/About/c0eghzelyjrqijzkmhks.jpg"
                alt="Shant Profile Pic"
                style={{
                  objectFit: "cover",
                  aspectRatio: "1/1",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <SubSection />
        <SocialMedia />
      </Box>
      <Box sx={{ height: "auto" }} pb={3}>
        <Container
          maxWidth="xl"
          sx={{ display: "flex", justifyContent: "center", padding: 3 }}
        >
          <Stack sx={{ width: "100%", maxWidth: 1345 }} spacing={10}>
            <Typography
              variant="h4"
              component="h2"
              color="text.primary"
              sx={styles.heading}
              pt={6}
            >
              Career Timeline
            </Typography>

            <Box display="flex" justifyContent="center">
              {/* <Grid item xs={12} md={12} sx> */}
              {/* <ProfileTimeline /> */}
              <VerticalTimeline timelineData={workTimeLine as TimelineData[]} />
              {/* </Grid> */}
            </Box>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={styles.gradientSec}
        display="flex"
        justifyContent="center"
        pb={6}
      >
        <Stack spacing={3}>
          <Typography
            variant="h4"
            component="h2"
            color="text.primary"
            sx={styles.headingW}
            pt={6}
          >
            Skills
          </Typography>

          <ListSection iconData={languageLogos} title="Languages" />
          <ListSection iconData={toolsLogos} title="Developer Tools" />
          <ListSection iconData={softwareLogos} title="Software" />
        </Stack>
      </Box>
    </Layout>
  );
};

export default About;

const styles = {
  heading: {
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
  },
  headingW: {
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    color: "white",
  },
  gradientSec: {
    background:
      "linear-gradient(45deg, rgba(120,174,255,1) 0%, rgba(0,0,0,1) 100%)",
  },
  coverImg: {
    objectFit: "cover",
    aspectRatio: "1/1",
    width: "100%",
    height: "100%",
  },
  profileImgBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 350,
    mx: "auto",
  },
};
