import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
import { useMediaQuery, useTheme, Link as MuiLink } from "@mui/material";

export interface TimelineData {
  date: string;
  title: string;
  company: string;
  link?: string;
  list: string[];
  description?: string;
}
export interface StepIconProps {
  active: boolean;
  completed: boolean;
  icon: number;
  timelineData: TimelineData[];
}
const CustomStepIcon = (props: any) => {
  const { active, completed, className, timelineData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let marginLeft = 0;
  switch (props.icon) {
    case 1:
      marginLeft = -148;
      break;
    case 2:
      marginLeft = -141;
      break;
    case 3:
      marginLeft = -148;
      break;
    case 4:
      marginLeft = -110;
      break;
    default:
      break;
  }
  return (
    <div
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      {!isMobile && (
        <Typography variant="body2" style={{ marginRight: 8, marginLeft }}>
          {timelineData[props.icon - 1].date}
        </Typography>
      )}

      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: "50%",
          backgroundColor: "#78AEFF",
          marginLeft: isMobile ? 6 : 0,
          marginTop: isMobile ? 6 : 0,
          // backgroundColor: active || completed ? 'blue' : 'grey',
        }}
      />
    </div>
  );
};

export default function VerticalLinearStepper(props: {
  timelineData: TimelineData[];
}) {
  const { timelineData } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        width: "100%",
        maxWidth: 1300,
        px: isMobile ? 0 : 4,
        marginLeft: !isMobile ? "175px" : 0,
      }}
    >
      <Box sx={{ maxWidth: "100%" }}>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {timelineData.length > 0 &&
            timelineData.map((step: TimelineData, index: number) => (
              <Step key={step.title} active={true} completed={true}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <CustomStepIcon {...props} timelineData={timelineData} />
                  )}
                  optional={
                    index === 2 ? (
                      <Typography variant="caption">{""}</Typography>
                    ) : null
                  }
                  sx={{ alignItems: isMobile ? "flex-start" : "center" }}
                >
                  {isMobile ? (
                    <>
                      <Typography>{step.date}</Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          fontWeight: 600,
                          alignItems: "center",
                          marginRight: 4,
                        }}
                      >
                        {step.title}
                      </Typography>
                      <Box display="flex">
                        <Typography sx={{ fontWeight: 400 }}>at</Typography>
                        <MuiLink
                          component={Link}
                          to={step.link || "#"}
                          sx={{ textDecoration: "none" }}
                        >
                          <Typography
                            sx={{ fontWeight: 400, color: "#0A6CFF" }}
                            ml={1}
                          >
                            {step.company}
                          </Typography>
                        </MuiLink>
                      </Box>
                    </>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: "flex",
                        fontWeight: 600,
                        alignItems: "center",
                        marginRight: 4,
                      }}
                    >
                      {step.title}
                      <Typography sx={{ fontWeight: 400 }} ml={1}>
                        at
                      </Typography>
                      <MuiLink
                        to={step.link || "#"}
                        component={Link}
                        sx={{ textDecoration: "none" }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            color: "#0A6CFF",
                          }}
                          ml={1}
                        >
                          {step.company}
                        </Typography>
                      </MuiLink>
                    </Typography>
                  )}
                </StepLabel>
                <StepContent>
                  {/* <Typography>{step.description}</Typography> */}
                  <ul>
                    {step.list &&
                      step.list.map((item: string, i: number) => (
                        <li key={i} style={{ marginBottom: 16 }}>
                          <Typography>{item}</Typography>
                        </li>
                      ))}
                  </ul>
                  {/* <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === timelineData.length - 1
                        ? 'Finish'
                        : 'Continue'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box> */}
                </StepContent>
              </Step>
            ))}
        </Stepper>
        {activeStep === timelineData.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </Box>
  );
}
