import React from 'react';
import Header from './Header';
import { ThemeProvider, Box } from '@mui/material';
import theme from '../theme';

interface Props {
  children: string | JSX.Element | JSX.Element[];
  parentRef?: any;
}
const Layout = ({ children, parentRef }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ background: '#f3f3f3', minHeight: '100vh' }}
        ref={parentRef && parentRef}
      >
        <Header />
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
