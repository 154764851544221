import React from "react";
import { Grid, Stack, useTheme, Box } from "@mui/material";
import GIthubLogo from "../../assets/social/Octicons-mark-github.svg";
import LinkedInLogo from "../../assets/social/LinkedIn_icon.svg";

const SocialMedia = () => {
  const icons = [
    {
      icon: GIthubLogo,
      alt: "Github acount",
      link: "https://github.com/sarslaniancsun",
    },
    {
      icon: LinkedInLogo,
      alt: "LinkedIn account",
      link: "https://linkedin.com/in/shantarslanian",
    },
  ];
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={5}
      mt={4}
    >
      {icons.map((item) => {
        return (
          <Box key={item.alt} sx={{ maxWidth: 40, height: "auto" }}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <img src={item.icon} alt={item.alt} style={{ width: "100%" }} />
            </a>
          </Box>
        );
      })}
    </Stack>
  );
};

export default SocialMedia;
