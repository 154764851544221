import React, { createContext, useReducer, useContext, ReactNode } from "react";

interface GlobalState {
  isDarkMode: boolean;
}

type Action = { type: "SET_DARK_MODE"; payload: boolean };

const initialState: GlobalState = {
  isDarkMode: false,
};

interface GlobalContextProps {
  state: GlobalState;
  dispatch: React.Dispatch<Action>;
}

// Define GlobalContext with GlobalContextProps
const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

const globalReducer = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case "SET_DARK_MODE":
      return { ...state, isDarkMode: action.payload };
    default:
      return state;
  }
};

interface IProps {
  children: ReactNode;
}

export const GlobalProvider = (props: IProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};

export const useSetDarkMode = () => {
  const { dispatch } = useGlobalState();
  return (bool: boolean) => dispatch({ type: "SET_DARK_MODE", payload: bool });
};
