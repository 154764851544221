// src/assets/languages/Android_robot.svg
import AndroidLogo from '../../assets/languages/Android_robot.svg';
import CssLogo from '../../assets/languages/css-3-svgrepo-com.svg';
import HtmlLogo from '../../assets/languages/html-5-logo-svgrepo-com.svg';
import CLogo from '../../assets/languages/c-1.svg';
import TypescripLogo from '../../assets/languages/typescript.svg';
import NodeJSLogo from '../../assets/languages/nodejs-svgrepo-com.svg';
import JavascriptLogo from '../../assets/languages/Unofficial_JavaScript_logo_2.svg';
import PythonLogo from '../../assets/languages/python-svgrepo-com.svg';
import CplusLogo from '../../assets//languages/cpp_logo.png';
import JavaLogo from '../../assets/languages/java-4-logo-svgrepo-com.svg';
import MySQLLogo from '../../assets/languages/mysql-logo-svgrepo-com.svg';
import ReactLogo from '../../assets/languages/logo.svg';

import VScodeIcon from '../../assets/tools/vscode.svg';
import GitIcon from '../../assets/tools/Git-Icon-Black.svg';
import IntelliJ from '../../assets/tools/IntelliJ_IDEA_Icon.svg';
import Eclipse from '../../assets/tools/eclipse-icon-svgrepo-com.svg';
import GCicon from '../../assets/tools/google-cloud.svg';
import AndroidStudio from '../../assets/tools/logo-google-android-studio-svgrepo-com.svg';

import XDicon from '../../assets/software/Adobe_XD_CC_icon.svg';
import FigmaIcon from '../../assets/software/apps-figma.svg';
import AzureIcon from '../../assets/software/azure-devops-svgrepo-com.svg';
import GithubIcon from '../../assets/software/github-svgrepo-com.svg';
import GitLabIcon from '../../assets/software/gitlab-svgrepo-com.svg';
import MiroIcon from '../../assets/software/Miro_id1rEl70oX_1.png';
import JiraIcon from '../../assets/software/jira-1.svg';
import officeIcon from '../../assets/software/office-365-icon.svg';

export const softwareLogos = [
  { name: 'Github', icon: GithubIcon },
  { name: 'Gitlab', icon: GitLabIcon },
  { name: 'Azure DevOps', icon: AzureIcon },
  { name: 'Figma', icon: FigmaIcon },
  { name: 'Adobe XD', icon: XDicon },
  { name: 'Miro', icon: MiroIcon },
  { name: 'Jira Atlassian', icon: JiraIcon },
  { name: 'MS Office 365', icon: officeIcon },
];

export const toolsLogos = [
  { name: 'Git', icon: GitIcon },
  { name: 'VS Code', icon: VScodeIcon },
  { name: 'IntelliJ', icon: IntelliJ },
  { name: 'Eclipse', icon: Eclipse },
  { name: 'GCP', icon: GCicon },
  { name: 'Android Studio', icon: AndroidStudio },
];

export const languageLogos = [
  { name: 'React', icon: ReactLogo },
  { name: 'Typescript', icon: TypescripLogo },
  {
    name: 'Javascript',
    icon: JavascriptLogo,
  },
  {
    name: 'NodeJS',
    icon: NodeJSLogo,
  },
  { name: 'Python', icon: PythonLogo },
  {
    name: 'Android SDK',
    icon: AndroidLogo,
  },
  {
    name: 'C',
    icon: CLogo,
  },
  { name: 'C++', icon: CplusLogo },

  {
    name: 'CSS3',
    icon: CssLogo,
  },
  {
    name: 'HTML 5',
    icon: HtmlLogo,
  },
  {
    name: 'Java',
    icon: JavaLogo,
  },
  {
    name: 'MySQL',
    icon: MySQLLogo,
  },
];
