import React, { useEffect, useState, useRef } from "react";
import { Marker, useMap } from "react-leaflet";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Slider,
  Dialog,
  DialogTitle,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import ChurchIcon from "@mui/icons-material/Church";
import FenceIcon from "@mui/icons-material/Fence";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import L from "leaflet";
import { LatLngTuple } from "leaflet";
import "./MarkersPopups.css"; // Import the external CSS file
import { styles } from "./styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useGlobalState } from "../../context/globalContext";
export interface MapData {
  name: string;
  latLng: LatLngTuple;
  img?: string;
  dialogContent: string;
  link?: string;
  type: string;
  date: string;
}

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});

const MarkersPopups: React.FC = () => {
  const { state } = useGlobalState();
  const map = useMap();
  const icons = [<ChurchIcon />, <LocationCityIcon />, <FenceIcon />];
  const [markers, setMakers] = useState<MapData[]>([]);
  const [filterType, setFilterType] = useState<string>("All");
  const [selectedYearRange, setSelectedYearRange] = useState<number[]>([
    0, 2024,
  ]);
  const [selectedMarker, setSelectedMarker] = useState<MapData | null>(null);
  const [clickedMarker, setClickedMarker] = useState<MapData | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openFilter, setOpenFilter] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleMarkerClick = (marker: MapData) => {
    setSelectedMarker(marker);
    setClickedMarker(marker);
    map.setView([marker.latLng[0], marker.latLng[1]]);
  };

  const handleClosePopup = () => {
    setSelectedMarker(null);
    setClickedMarker(null);
  };

  const fetchAllMarkers = async () => {
    const res = await fetch("/api/marker/all");
    const json = await res.json();
    if (res.ok) {
      setMakers(json);
    }
  };
  useEffect(() => {
    fetchAllMarkers();
  }, []);
  const searchTerms = [
    { name: "All", label: "All" },
    { name: "church", label: "Churches" },
    { name: "monument", label: "Monuments" },
    { name: "cemetery", label: "Cemeteries" },
  ];
  const filteredMarkers = markers.filter((marker) => {
    const markerYear = parseInt(marker.date, 10); // Convert string to integer
    const isTypeMatch = filterType === "All" || marker.type === filterType;
    const isYearInRange =
      markerYear >= selectedYearRange[0] && markerYear <= selectedYearRange[1];

    return isTypeMatch && isYearInRange;
  });
  const handleEvent = () => {
    map.dragging.disable();
  };
  const handleMouseLeave = () => {
    map.dragging.enable();
  };
  const toggleIconButton = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      if (
        contentRef.current &&
        contentRef.current.contains(event.target as Node)
      ) {
        event.stopPropagation();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
  return (
    <>
      {isMobile && (
        <IconButton
          color="primary"
          onClick={toggleIconButton}
          sx={styles.iconBtnFilter}
          style={{ backgroundColor: state.isDarkMode ? "black" : "white" }}
        >
          <TuneIcon />
        </IconButton>
      )}
      <Box
        sx={isMobile ? styles.filterBoxMobile : styles.filterBox}
        onMouseOver={handleEvent}
        onMouseLeave={handleMouseLeave}
        style={{
          display: isMobile ? (openFilter ? "block" : "none") : "block",
          backgroundColor: state.isDarkMode ? "black" : "white",
          color: state.isDarkMode ? "primary.main" : "primary.dark",
        }}
      >
        {isMobile && (
          <IconButton
            color="primary"
            onClick={toggleIconButton}
            sx={styles.iconBtnClose}
          >
            <CloseIcon />
          </IconButton>
        )}
        <>
          {searchTerms.map((item, i) => {
            const isSelected = filterType === item.name;
            return (
              <Button
                key={item.name}
                onClick={() => setFilterType(item.name)}
                variant={isSelected ? "contained" : "outlined"}
                sx={{
                  marginRight: 1,
                  textTransform: "capitalize",
                  borderRadius: 20,
                  borderColor: state.isDarkMode
                    ? "primary.main"
                    : "primary.dark",
                  color: state.isDarkMode
                    ? [isSelected ? "black" : "primary.main"]
                    : "black",

                  marginTop: isMobile && i > 1 ? 1 : 0,
                }}
                startIcon={i !== 0 ? icons[i - 1] : undefined}
              >
                {item.label}
              </Button>
            );
          })}
          <Box className="sliderContainer" paddingLeft={1} marginTop={2}>
            <Typography
              id="year-slider"
              variant="body1"
              sx={{
                fontSize: 14,
                color: state.isDarkMode ? "primary.main" : "black",
              }}
              marginLeft={-1}
            >
              Year Built
            </Typography>
            <Slider
              value={selectedYearRange}
              onChange={(_, newValue: number | number[]) => {
                if (Array.isArray(newValue)) {
                  setSelectedYearRange(newValue);
                }
              }}
              valueLabelDisplay="auto"
              min={0}
              max={2024}
              step={1}
              marks={[
                { value: 0, label: "0 AD" },
                { value: 1000, label: "1000" },
                { value: 1500, label: "1500" },
                { value: 2024, label: "2024" },
              ]}
              aria-labelledby="year-slider"
              sx={{
                width: "100%",
                "& .MuiSlider-markLabel": {
                  color: state.isDarkMode ? "primary.main" : "black",
                },
              }}
            />
          </Box>
        </>
      </Box>
      {filteredMarkers.length &&
        filteredMarkers.map((data: any, i) => {
          return (
            <Marker
              position={data.latLng}
              key={data.name}
              eventHandlers={{ click: () => handleMarkerClick(data) }}
            >
              {selectedMarker && selectedMarker.name === data.name && (
                <Dialog
                  open={Boolean(selectedMarker)}
                  onClose={handleClosePopup}
                  fullWidth
                  maxWidth="sm"
                  sx={{ borderRadius: 8 }}
                  PaperProps={{
                    style: {
                      maxHeight: isMobile
                        ? "calc(100vh - 80px)" // Ensure it fits within the viewport on mobile
                        : "80vh",
                    },
                  }}
                >
                  <Card>
                    {selectedMarker.img && (
                      <CardMedia
                        component="img"
                        alt={selectedMarker.name}
                        height="400"
                        image={selectedMarker.img}
                        sx={{ objectFit: "cover" }}
                      />
                    )}
                    <CardContent
                      ref={contentRef}
                      style={{
                        overflowWrap: "anywhere",
                        backgroundColor: state.isDarkMode ? "black" : "white",
                        overflowY: "auto", // Enable scrolling
                        WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS
                        paddingBottom: 16,
                        maxHeight: selectedMarker.img
                          ? "calc(100vh - 300px)" // Adjust content height for scrolling
                          : "calc(100vh - 100px)",
                      }}
                    >
                      <DialogTitle
                        variant="subtitle1"
                        style={{
                          fontWeight: "bold",
                          padding: 0,
                          color: state.isDarkMode ? "#78AEFF" : "black",
                        }}
                        mb={2}
                      >
                        {selectedMarker.name}
                      </DialogTitle>
                      <Typography
                        variant="body1"
                        style={{
                          color: state.isDarkMode ? "#78AEFF" : "black",
                        }}
                      >
                        {selectedMarker.dialogContent}
                      </Typography>
                      {selectedMarker.link && (
                        <Button
                          href={selectedMarker.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="outlined"
                          sx={{
                            marginTop: 2,
                            textTransform: "capitalize",
                            borderRadius: 20,
                            borderColor: state.isDarkMode
                              ? "primary.main"
                              : "primary.dark",
                            color: state.isDarkMode
                              ? "primary.main"
                              : "primary.dark",
                          }}
                          endIcon={<OpenInNewIcon />}
                        >
                          Learn more
                        </Button>
                      )}
                      <Box
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          borderRadius: "75%",
                          padding: 0,
                        }}
                      >
                        <IconButton
                          aria-label="close"
                          onClick={handleClosePopup}
                          sx={{
                            color: "white",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </Dialog>
              )}
            </Marker>
          );
        })}
    </>
  );
};

export default MarkersPopups;
