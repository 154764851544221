import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';

interface IconData {
  name: string;
  icon: string;
}
interface IProps {
  title: string;
  iconData: IconData[];
}
const ListSection = (data: IProps) => {
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:400px)');
  const padding10 = {
    padding: `0 1rem`,
    '@media (min-width: 400px)': {
      padding: `0 2rem`,
    },
    [theme.breakpoints.up('sm')]: {
      padding: `0 2rem`,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: `center`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `0 5rem`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `0 10rem`,
    },
  };
  const containerSx = {
    justifyContent: `center`,
    [theme.breakpoints.up('md')]: {
      justifyContent: `left`,
    },
  };
  return (
    <Stack mx={matches ? 2 : 5} justifyContent="center" sx={padding10}>
      <Typography
        variant="h5"
        component="h3"
        color="text.primary"
        sx={styles.heading}
        pt={3}
        mb={16}
      >
        {data.title}
      </Typography>
      <Grid container spacing={3} sx={containerSx}>
        {data.iconData.map((item, i) => {
          // const str = item.url as unknown as string;
          return (
            <Grid item key={item.name} md={2}>
              <Box sx={styles.box}>
                <img
                  src={item.icon}
                  alt={item.name}
                  style={{ width: 70, height: 70, borderRadius: 4 }}
                />
                <Typography style={{ fontSize: 13, color: 'gray' }}>
                  {item.name}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
export default ListSection;

const styles = {
  heading: {
    // margin: 'auto',
    fontWeight: 600,
    color: 'white',
    marginBottom: 0.5,
  },
  box: {
    textAlign: 'center',
    background: 'white',
    borderRadius: 4,
    padding: 1,
  },
};
