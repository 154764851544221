import React, { useEffect, useState } from "react";
import { LeafletMouseEvent } from "leaflet";
import Layout from "../../components/Layout";
import "leaflet/dist/leaflet.css";
import { Typography, useMediaQuery } from "@mui/material";
import "./styles.css";
import Map from "./Map";
import { useGlobalState } from "../../context/globalContext";
import { grey } from "@mui/material/colors";

const InteractiveMap = () => {
  const [latLng, setLatLng] = useState<LeafletMouseEvent["latlng"]>();
  const [rerender, setRerender] = useState<boolean>(true);
  const isAboveTablet = useMediaQuery("(min-width:420px)");
  const { state } = useGlobalState();
  useEffect(() => {
    if (!rerender) {
      setRerender(true);
    }
  }, [rerender]);
  return (
    <Layout>
      <Map setLatLng={setLatLng} />
      <Typography
        variant="body1"
        sx={{
          height: 56,
          background: state.isDarkMode ? "black" : "white ",
          color: state.isDarkMode ? grey[400] : grey[600],
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          paddingRight: 2,
          fontSize: 14,
        }}
      >
        Latitude: {latLng && latLng.lat.toFixed(6)} {!isAboveTablet && <br />}{" "}
        Longitude: {latLng && latLng.lng.toFixed(6)}
      </Typography>
    </Layout>
  );
};

export default InteractiveMap;
