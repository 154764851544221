import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  Container,
  Box,
  Button,
  Link,
  Snackbar,
  Alert,
  useMediaQuery,
} from "@mui/material";
import Layout from "../../components/Layout";
import "./styles.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AbbreviationTitle from "./AbbreviationTitle";
import ChurchIcon from "@mui/icons-material/Church";
import { useGlobalState } from "../../context/globalContext";
const data = [
  { title: "Author", name: "Shant Serop Arslanian" },
  {
    title: "Thesis Board",
    name: "Professor Vahe Karamian, Dr. Katya Mkrtchyan",
  },
  { title: "Chair", name: "Dr. Ani Nahapetian" },
];

const Thesis: React.FC = () => {
  const [open, setOpen] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width:660px)");
  const isMobile = useMediaQuery("(max-width:420px)");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Layout>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "center",
          py: 3,
          px: isLargeScreen ? 3 : 0,
          mx: 0,
          backgroundColor: "black",
          backgroundImage:
            "linear-gradient(40deg, rgba(120,174,255,1) -10%, rgba(0,0,0,1) 60%, rgba(120,174,255,1) 140%)",
          minWidth: "100vw",
          minHeight: "100vh",
          alignItems: "baseline",
        }}
      >
        <Stack
          sx={{ width: "100%", color: "white" }}
          spacing={isLargeScreen ? 16 : 6}
          px={2}
        >
          <Box mt={2}>
            <Box
              mx="auto"
              sx={{ width: "max-content" }}
              display="flex"
              alignItems="baseline"
              flexDirection={isLargeScreen ? "row" : "column"}
            >
              <ChurchIcon fontSize="large" sx={{ marginRight: 2 }} />
              <AbbreviationTitle letter="AR" word="menian" />
              <AbbreviationTitle letter="E" word="xploration" />
              <AbbreviationTitle letter="V" word="isualization" />
            </Box>

            <Typography
              variant="h6"
              color="#78AEFF"
              component="div"
              sx={{ textAlign: "center" }}
              mt={3}
              px={isLargeScreen ? 0 : 1}
            >
              Visualization Infrastructure for Cultural Heritage Exploration
            </Typography>
          </Box>
          <Grid
            container
            sx={{ color: "white" }}
            px={isMobile ? 1 : 0}
            mt={3}
            gap={isLargeScreen ? 0 : 3}
          >
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Stack spacing={3}>
                {data.map((item, i) => {
                  return (
                    <Box
                      display="flex"
                      key={item.title}
                      flexDirection={isLargeScreen ? "row" : "column"}
                    >
                      <Typography
                        variant="body1"
                        color="lightgray"
                        sx={{ width: 136 }}
                      >
                        {item.title}:
                      </Typography>
                      <Typography
                        color="white"
                        component="span"
                        sx={{ fontWeight: 600 }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  );
                })}

                <Button
                  endIcon={<OpenInNewIcon />}
                  variant="outlined"
                  sx={{
                    width: "fit-content",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    color: "#78AEFF",
                    background: "black",
                    borderColor: "#78AEFF",
                    "&:hover": {
                      background: "#78AEFF",
                      color: "black",
                      borderColor: "black",
                    },
                  }}
                  // onClick={handleClick}
                  onClick={() =>
                    window.open(
                      "https://scholarworks.calstate.edu/concern/theses/ft849023b",
                      "_blank"
                    )
                  }
                >
                  Read this thesis
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ lineHeight: 2 }}>
                This thesis explores a visualization system for engaging with
                the rich cultural heritage underlying the territorial disputes
                between Artsakh and Azerbaijan. <br />
                <br /> Over the past few decades, Azerbaijan’s initiation of
                border conflicts has resulted in the unjust appropriation of
                lands historically belonging to Armenia/Artsakh.
                <br />
                <br /> To demonstrate this narrative, this user-friendly
                web-based application showcases a map interface enabling users
                to effortlessly explore the cultural heritage sites seized by
                Azerbaijan from Artsakh.
                <br />
                <br /> The application and system evaluation shows that the
                application employs popular design patterns such as filter
                buttons, markers, zoom, etc., for users to{" "}
                <Box
                  component="span"
                  sx={{ borderBottom: "5px solid #78AEFF" }}
                >
                  explore major landmarks signifying the land’s cultural
                  heritage
                </Box>{" "}
                while learning about the history of the territorial disputes
                between the two countries.
                <br />
                <br /> The application’s implementation prioritizes optimization
                efforts aimed at enhancing the loading performance of markers on
                the map and the images in the popup, addressing a critical UI
                requirement of responsiveness.
              </Typography>
            </Grid>
          </Grid>
        </Stack>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
            The Thesis will be posted once it is approved
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
};

export default Thesis;
