export const styles = {
  headerText: {
    textDecoration: 'none',
  },
  headerLogoText: {
    mr: 2,
    display: { xs: 'flex', md: 'none' },
    flexGrow: 1,
    fontFamily: 'monospace',
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: 'inherit',
    textDecoration: 'none',
  },
  mt45px: { mt: '45px' },
  appBar: {
    boxShadow: '0px 3px 12px 0px rgbs(0,0,0, 0.09)',
    backgroundColor: 'white',
  },
  siteTitle: {
    mr: 2,
    display: { xs: 'none', md: 'flex' },
    fontFamily: 'monospace',
    fontWeight: 700,
    color: 'inherit',
    textDecoration: 'none',
  },
  menuBox: {
    flexGrow: 1,
    display: { xs: 'none', md: 'flex' },
    justifyContent: 'right',
    alignItems: 'center',
  },
};