import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import InteractiveMap from './pages/map';
import About from './pages/about';
import Thesis from './pages/thesis';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <About />,
    },
    {
      path: '/thesis',
      element: <InteractiveMap />,
    },
    {
      path: '/about-thesis',
      element: <Thesis />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
