import React from "react";
import { Box, Typography } from "@mui/material";

interface IProps {
  letter: string;
  word: string;
}
const AbbreviationTitle = (props: IProps) => {
  const { letter, word } = props;
  return (
    <Box display="flex" alignItems="baseline">
      <Typography variant="h3" sx={{ fontWeight: 600 }}>
        {letter}
      </Typography>
      <Typography variant="h5" ml={0.5} mr={2}>
        {word}
      </Typography>
    </Box>
  );
};

export default AbbreviationTitle;
