import zIndex from '@mui/material/styles/zIndex';

export const styles = {
  mapContainer: {
    height: 'calc(100vh - 112px)',
  },
  filterBox: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 1000,
    backgroundColor: 'black',
    padding: 2,
    borderRadius: 2,
    // color: 'primary.main',
  },
  filterBoxMobile: {
    position: 'absolute',
    top: 8,
    right: 0,
    zIndex: 1000,
    backgroundColor: 'black',
    padding: 2,
    borderRadius: 2,
  },
  iconBtnFilter: {
    position: 'absolute',
    right: 16,
    top: 16,
    zIndex: 1000,
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
  },
  iconBtnClose: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1000,
    // backgroundColor: 'black',
  },
};