import { createTheme, alpha, getContrastRatio } from '@mui/material';

const primary = '#78AEFF';
const theme = createTheme({
  typography: {
    fontFamily: 'monospace',
  },
  palette: {
    secondary: {
      main: '#78AEFF',
    },
    primary: {
      main: '#78AEFF',
      light: alpha(primary, 0.5),
      dark: '#0064FA',
      contrastText: getContrastRatio(primary, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
});

export default theme;