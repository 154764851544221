import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import { useGlobalState } from "../context/globalContext";

const pages = [
  { title: "Thesis", link: "/thesis" },
  { title: "About Thesis", link: "/about-thesis" },
];

export default function SubMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { state } = useGlobalState();
  const { pathname } = useLocation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isActive = pathname === pages[0].link || pathname === pages[1].link;

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          color: isActive
            ? state.isDarkMode
              ? "primary.main"
              : "black"
            : "gray",
          fontWeight: isActive ? "bold" : "normal",
          fontSize: 18,
        }}
        onClick={handleClick}
      >
        Thesis
      </Button>
      <Menu
        id="sub-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        {pages.map((page) => {
          return (
            <MenuItem
              key={`page-${page.title}`}
              component={Link}
              to={page.link}
              onClick={handleClose}
            >
              {page.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
