import { Polygon } from "react-leaflet";
import { LatLngExpression } from "leaflet";

interface PolygonData {
  color: string;
  weight: number;
  opacity: number;
  polygon: LatLngExpression[] | LatLngExpression[][];
}

export const polygons: PolygonData[] = [
  // {
  //   color: "blue",
  //   weight: 1,
  //   opacity: 1,
  //   polygon: [
  //     [41.219732, 47.373315],
  //     [41.151416, 47.815666],
  //     [41.405819, 47.987283],
  //     [41.80887, 48.584353],
  //     [41.282287, 49.110264],
  //     [40.572924, 49.618915],
  //     [40.526157, 50.08483],
  //     [40.256561, 50.392821],
  //     [40.176101, 49.569202],
  //     [39.399482, 49.395259],
  //     [39.049219, 49.223228],
  //     [38.815486, 48.856532],
  //     [38.320245, 48.883249],
  //     [38.270378, 48.634375],
  //     [38.794015, 48.010744],
  //     [39.288765, 48.355529],
  //     [39.582235, 48.060095],
  //     [39.508364, 47.685079],
  //     [38.770605, 46.50572],
  //     [39.464155, 46.483499],
  //     [39.628021, 46.034534],
  //     [39.899994, 45.610012],
  //     [40.218476, 45.891907],
  //     [40.561504, 45.359175],
  //     [40.81229, 45.560351],
  //     [40.985354, 45.179496],
  //     [41.248129, 44.97248],
  //     [41.411452, 45.217426],
  //     [41.123873, 45.962601],
  //     [41.064445, 46.501637],
  //     [41.181673, 46.637908],
  //     [41.722802, 46.145432],
  //     [41.860675, 46.404951],
  //     [41.827137, 46.686071],
  //     [41.219732, 47.373315],
  //   ],
  // },
  // {
  //   color: "red",
  //   weight: 2,
  //   opacity: 1,
  //   polygon: [
  //     [41.092143, 43.582746],
  //     [41.248129, 44.97248],
  //     [40.985354, 45.179496],
  //     [40.81229, 45.560351],
  //     [40.561504, 45.359175],
  //     [40.218476, 45.891907],
  //     [39.899994, 45.610012],
  //     [39.628021, 46.034534],
  //     [39.464155, 46.483499],
  //     [38.770605, 46.50572],
  //     [38.741201, 46.143623],
  //     [39.319719, 45.735379],
  //     [39.473999, 45.739978],
  //     [39.471751, 45.298145],
  //     [39.740004, 45.001987],
  //     [39.713003, 44.79399],
  //     [40.005, 44.400009],
  //     [40.253564, 43.656436],
  //     [40.740201, 43.752658],
  //     [41.092143, 43.582746],
  //   ],
  // },
];
