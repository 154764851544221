import React from 'react';
import { Polygon } from 'react-leaflet';
import { polygons } from '../../data/polygons';

const Polygons = () => {
  return (
    <>
      {polygons.map((data, i) => {
        return (
          <Polygon
            key={`polygons-${i}`}
            pathOptions={{ color: data.color }}
            positions={data.polygon}
          />
        );
      })}
    </>
  );
};

export default Polygons;
